import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from '../components/SEO'
import Layout from "../components/Layout"
import fetch from "node-fetch"
import index from '../pages/index';


function sendCompanyEmail(e, body) {  
  e.preventDefault()

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "http://organiklean.com.au");

  var raw = JSON.stringify({"subject":"Website Booking","desc":body});

  var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
  };
  

  fetch("https://9tpoahk7h8.execute-api.ap-southeast-2.amazonaws.com/02/contact-us", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
  
  window.location.href = "http://www.organiklean.com.au/thankyou";
  
}


class ContactIndex extends React.Component {
  constructor(props) {
    super(props);
  } 
  state = {
    name: "",
    phone: "",
    email: "",
    company: "",
    message: ""
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {    
    event.preventDefault()
    sendCompanyEmail(event, "Website Contact", String(this.state.name+"\n"+this.state.phone+"\n"+this.state.email+"\n"+this.state.company+"\n"+this.state.message))
    // window.location = '/thankyou'    
  }
  
  render() {  
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')    
    var all_seo = this.props.data.allContentfulPageSeo.edges;
    var pagename = 'contact'    
    var seo = []
    for (var i = 0; i < all_seo.length; i++) {
      var id_exists = false;
      for (var j =0; j < seo.length; j++) {
        if (all_seo[i].node.contentId == seo[j].node.contentId) { id_exists = true;}
      }      
      if (id_exists == false & all_seo[i].node.pageName == pagename) { seo.push(all_seo[i])}
    }  
    return (
      <Layout location='Contact' >
        <SEO pageSEOdata={seo}/>
        <div style={{ background: '#fff' }}>          
          <div className='contact_header'style={{color:'#ffffff'}} />            
          <div className="page_wrapper">
            <h1 className="section-headline">Contact Us</h1>
            <div className="contact_wrapper">                                    
              <form id="contact-form" method="post" action={index} onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <h4>Name:</h4>
                  <input type="text" id="name-input" placeholder="Name..." className="form-control" name="name" value={this.state.name} onChange={this.handleInputChange} />
                </div>
                <div className="form-group">
                  <h4>Phone:</h4>
                  <input type="text" id="phone-input" placeholder="Phone..." className="form-control" name="phone" value={this.state.phone} onChange={this.handleInputChange}/>
                </div>
                <div className="form-group">
                  <h4>Email:</h4>
                  <input type="email" id="email-input" placeholder="Email..." className="form-control" name="email" value={this.state.email} onChange={this.handleInputChange}/>
                </div>                
                <div className="form-group">
                  <h4>Message:</h4>
                  <textarea id="description-input" rows="3" placeholder="Message…" className="form-control" name="message" value={this.state.message} onChange={this.handleInputChange}></textarea>
                </div>                
                <button type="submit" className="contact_submit_button">Submit</button>
              </form>                
            </div>                        
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactIndex

export const pageQuery = graphql`
  query ContactIndexQuery {
    site {
      siteMetadata {
        title
      }
    }    
    allContentfulPageSeo(filter: {siteName: {eq: "Organiklean"}}) {
      edges {
        node {
          contentId
          description
          googleSiteVerification
          keywords {
            keywords
          }
          ogdescription
          oglocale
          ogtitle
          ogtype
          ogurl
          pageName
          siteName
          title
          twittercard
          twittercreator
          twitterdescription
          twittertitle
        }
      }
    }   
  }
`